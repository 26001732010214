import { IconButton } from "@mui/material";
import { useAppSelector } from "../../Redux/hooks";
import { MoreVert } from "@mui/icons-material";
import React from "react";

type Props = {
  clickHandler: Function;
};
const QuestionItemMenuButton: React.FC<Props> = ({ clickHandler }) => {
  const isResponseSubmitting = useAppSelector(
    (state) => state.response.fieldSubmitting
  );
  const isResponseLoading = useAppSelector((state) => state.response.isLoading);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isResponseSubmitting || isResponseLoading) return;

    clickHandler(event.currentTarget);
  };

  return (
    <IconButton onClick={handleClick}>
      <MoreVert color="primary" />
    </IconButton>
  );
};

export default React.memo(QuestionItemMenuButton);
