import {
  Box,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NoDataContainer,
  StyledTableCell,
  TableBorderRadiusTopLeftRight,
} from "../../../Common/styles/table";
import {
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  // Edit,
  ExpandMore,
} from "@mui/icons-material";
// import { AxiosResponse } from "axios";
// import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";

const dummyData = [
  {
    id: "randomization",
    name: "Randomization",
    expanded: false,
    selected: true,
    subItems: [
      {
        id: "subfeat1",
        name: "Sub feature 1",
        selected: false,
      },
      {
        id: "subfeat2",
        name: "Sub feature 2",
        selected: false,
      },
    ],
  },
  {
    id: "rpd",
    name: "Repeating Data",
    expanded: false,
    selected: false,
    subItems: [
      {
        id: "subfeat1",
        name: "Sub feature 1",
        selected: false,
      },
      {
        id: "subfeat2",
        name: "Sub feature 2",
        selected: false,
      },
    ],
  },
  {
    id: "survey-packages",
    name: "Survey Packages",
    expanded: false,
    selected: false,
    subItems: [
      {
        id: "subfeat1",
        name: "Sub feature 1",
        selected: false,
      },
      {
        id: "subfeat2",
        name: "Sub feature 2",
        selected: false,
      },
    ],
  },
  {
    id: "queries",
    name: "Queries",
    expanded: false,
    selected: false,
    subItems: [
      {
        id: "subfeat1",
        name: "Sub feature 1",
        selected: false,
      },
      {
        id: "subfeat2",
        name: "Sub feature 2",
        selected: false,
      },
    ],
  },
  {
    id: "export",
    name: "Export",
    expanded: false,
    selected: false,
    subItems: [
      {
        id: "subfeat1",
        name: "Sub feature 1",
        selected: false,
      },
      {
        id: "subfeat2",
        name: "Sub feature 2",
        selected: false,
      },
    ],
  },
];

const Features = ({
  toggleLoader,
  editFeatures,
  handleCloseEditFeatures,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const res1: AxiosResponse = await http.get(`/roles/matrix?studyId=`);
        // const rolesData = res1?.data?.data;

        // const formattedData = formatRolesAndPermissions(
        //   rolesData,
        //   permissionsData
        // );
        // setData(formattedData || { headers: [], rows: [] });
        setData(dummyData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [toggleLoader, setLoading]);

  const handleExpandToggle = (id: string) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, expanded: !item.expanded } : item
      )
    );
  };
  const handleCheckboxToggle = (parentId: string, subItemId?: string) => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.id === parentId) {
          if (subItemId) {
            const updatedSubItems = item.subItems.map((subItem: any) =>
              subItem.id === subItemId
                ? { ...subItem, selected: !subItem.selected }
                : subItem
            );
            return { ...item, subItems: updatedSubItems };
          } else {
            const newSelectedState = !item.selected;
            const updatedSubItems = item.subItems.map((subItem: any) => ({
              ...subItem,
              selected: newSelectedState,
            }));
            return {
              ...item,
              selected: newSelectedState,
              subItems: updatedSubItems,
            };
          }
        }
        return item;
      })
    );
  };

  return (
    <Box>
      <Table sx={TableBorderRadiusTopLeftRight}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{
                width: "400px",
              }}
            ></StyledTableCell>
            <StyledTableCell>Applicable</StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && data?.length > 0 && (
          <TableBody>
            {data?.map((row: any) => (
              <React.Fragment key={row?.id}>
                <TableRow>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <IconButton onClick={() => handleExpandToggle(row?.id)}>
                        <ExpandMore />
                      </IconButton>
                      <Typography
                        fontSize={16}
                        fontWeight={600}
                        color={"primary.main"}
                      >
                        {row?.name}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {!editFeatures ? (
                      row?.selected ? (
                        <Check sx={{ color: "primary.main" }} />
                      ) : (
                        <Typography
                          fontSize={16}
                          fontWeight={400}
                          color={"primary.main"}
                        >
                          —
                        </Typography>
                      )
                    ) : (
                      <Checkbox
                        checked={row?.selected}
                        icon={<CheckBoxOutlineBlank />}
                        onChange={() => handleCheckboxToggle(row?.id)}
                        checkedIcon={<CheckBox />}
                      />
                    )}
                  </StyledTableCell>
                </TableRow>
                {row?.expanded &&
                  row?.subItems?.map((subItem: any) => (
                    <TableRow key={subItem.id}>
                      <StyledTableCell>
                        <Typography
                          fontSize={16}
                          fontWeight={400}
                          sx={{ ml: 8 }}
                        >
                          {subItem.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {!editFeatures ? (
                          subItem.selected ? (
                            <Check sx={{ color: "primary.main" }} />
                          ) : (
                            <Typography
                              fontSize={14}
                              fontWeight={400}
                              color={"primary.main"}
                            >
                              —
                            </Typography>
                          )
                        ) : (
                          <Checkbox
                            checked={subItem.selected}
                            icon={<CheckBoxOutlineBlank />}
                            onChange={() =>
                              handleCheckboxToggle(row.id, subItem.id)
                            }
                            checkedIcon={<CheckBox />}
                          />
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      {!loading && data?.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export default Features;
