import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  Modal,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ClearIcon } from "@mui/x-date-pickers";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { setOrganisationFilter } from "../../Redux/reducers/organisationSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  // studyId: any;
};
const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Active", value: "active" },
  { label: "Suspended", value: "suspended" },
  { label: "Archived", value: "archived" },
  // { label: "Deleted", value: "deleted" },
];

const typeOptions = [
  { label: "Individual Organisation", value: "individual" },
  { label: "White Label Organisation", value: "white_label" },
];

let schema = yup.object().shape({
  // organisationId: yup.string().optional(),
  // organisationName: yup.string().optional(),
  // primaryContact: yup.string().optional(),
  filterType: yup.string().optional(),
  status: yup.string().optional(),
});

const OrganisationFilterModal = ({ showModal, closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const {
    // organisationId,
    // organisationName,
    // primaryContact,
    filterType,
    status,
  } = useAppSelector((state) => state.organisation);

  const submitHandler = async (values: any) => {
    dispatch(
      setOrganisationFilter({
        // organisationId: values?.organisationId,
        // organisationName: values?.organisationName,
        // primaryContact: values?.primaryContact,
        filterType: values?.filterType,
        status: values?.status,
      })
    );
    closeModal();
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Filters"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            // organisationId: organisationId || "",
            // organisationName: organisationName || "",
            // primaryContact: primaryContact || "",
            filterType: filterType || "",
            status: status || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, setFieldValue, values }) => (
            <Form>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="organisation-id">
                    Organisation ID
                  </FormLabel>
                  <TextField
                    id="organisation-id"
                    placeholder="Organisation ID"
                    {...getFieldProps("organisationId")}
                    error={
                      touched?.organisationId && errors?.organisationId
                        ? true
                        : false
                    }
                    helperText={
                      touched?.organisationId && errors?.organisationId
                        ? (errors?.organisationId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="organisation-name">
                    Organisation Name
                  </FormLabel>
                  <TextField
                    id="organisation-name"
                    placeholder="Organisation Name"
                    {...getFieldProps("organisationName")}
                    error={
                      touched?.organisationName && errors?.organisationName
                        ? true
                        : false
                    }
                    helperText={
                      touched?.organisationName && errors?.organisationName
                        ? (errors?.organisationName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}

              {/* <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="primary-contact">
                    Primary Contact
                  </FormLabel>
                  <TextField
                    id="primary-contact"
                    placeholder="Primary Contact"
                    {...getFieldProps("primaryContact")}
                    error={
                      touched?.primaryContact && errors?.primaryContact
                        ? true
                        : false
                    }
                    helperText={
                      touched?.primaryContact && errors?.primaryContact
                        ? (errors?.primaryContact as string)
                        : " "
                    }
                  />
                </FormControl> */}
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>Type</FormLabel>
                <Select
                  value={values.filterType}
                  onChange={(e) => {
                    setFieldValue("filterType", e.target.value);
                  }}
                  fullWidth
                  id="type"
                  displayEmpty
                  renderValue={
                    values.filterType !== ""
                      ? undefined
                      : () => (
                          <Typography sx={{ color: "#c1cccf" }}>
                            Select
                          </Typography>
                        )
                  }
                  endAdornment={
                    values.filterType !== "" && (
                      <InputAdornment
                        sx={{ marginRight: "10px" }}
                        position="end"
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue("filterType", "");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  {typeOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={
                    touched?.filterType && errors?.filterType ? true : false
                  }
                >
                  {touched?.filterType && errors?.filterType
                    ? (errors?.filterType as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>Status</FormLabel>
                <Select
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  fullWidth
                  id="status"
                  displayEmpty
                  renderValue={
                    values.status !== ""
                      ? undefined
                      : () => (
                          <Typography sx={{ color: "#c1cccf" }}>
                            Select
                          </Typography>
                        )
                  }
                  endAdornment={
                    values.status !== "" && (
                      <InputAdornment
                        sx={{ marginRight: "10px" }}
                        position="end"
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue("status", "");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  {statusOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.status && errors?.status ? true : false}
                >
                  {touched?.status && errors?.status
                    ? (errors?.status as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <Box sx={ModalActionButtonStyles}>
                <>
                  <Button
                    onClick={() => {
                      // setFieldValue("organisationId", "");
                      // setFieldValue("organisationName", "");
                      // setFieldValue("primaryContact", "");
                      setFieldValue("filterType", "");
                      setFieldValue("status", "");
                    }}
                    variant="outlined"
                  >
                    Clear All
                  </Button>
                  <Button onClick={closeModal} variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Apply
                  </Button>
                </>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OrganisationFilterModal;
