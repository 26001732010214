import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { HeaderStyle } from "../Common/styles/header";
import FeaturesList from "./FeaturesList";

const Features = () => {
  const [showModal, setShowModal] = useState<string>("");

  return (
    <>
      <Box sx={HeaderStyle}>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          Features List
        </Typography>

        <Box sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal("feature")}
          >
            New Feature
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3, height: "calc(100vh - 100px)", overflow: "auto" }}>
        <FeaturesList showModal={showModal} setShowModal={setShowModal} />
      </Box>
    </>
  );
};

export default Features;
