export const validateVarname = (value: string) => {
  const startsWithInvalidChar = /^[0-9_-]/;

  const regex = /^[a-zA-Z][a-zA-Z0-9_-]*$/;

  if (startsWithInvalidChar.test(value)) {
    return "Variable names should start with alphabets";
  } else if (!regex.test(value)) {
    return "Allowed characters: Alphanumeric, hyphen(-) and underscore(_)";
  }
  return "";
};
