import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setOrganisationData,
  setOrgLoader,
  setOrganisationCount,
  setOrganisationIsEmpty,
} from "../reducers/organisationSlice";
import { AppThunk } from "../store";

export const fetchOrgData =
  (
    searchText: string,
    organisationId: string,
    organisationName: string,
    primaryContact: string,
    filterType: string,
    status: string,
    sortOrder: string,
    sortColumn: string
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(setOrgLoader(true));
      let url: string = `/organizations?`;
      if (searchText) {
        url += `&query=${searchText}`;
      }
      if (status) {
        url += `&status=${status}`;
      }
      if (organisationId) {
        url += `&organisationId=${organisationId}`;
      }
      if (organisationName) {
        url += `&organisationName=${organisationName}`;
      }
      if (primaryContact) {
        url += `&primaryContact=${primaryContact}`;
      }
      if (filterType) {
        url += `&type=${filterType}`;
      }
      if (sortOrder && sortColumn) {
        url += `&sortKey=${sortColumn}&sortDirection=${sortOrder}`;
      }

      const res: AxiosResponse = await http.get(url);
      const data = res?.data?.data;

      const newData = data?.map((item: any) => {
        return {
          id: item?.id,
          orgId: item?.orgId || "-",
          logoUrl: item?.logoUrl,
          signedLogoUrl: item?.signedLogoUrl,
          orgName: item?.name || "-",
          parentOrg: item?.parentOrg || "-",
          primaryContact: item?.mainContactName || "-",
          type: item?.type || "-",
          status: item?.status || "-",
        };
      });

      dispatch(setOrganisationData(newData));
      dispatch(setOrganisationCount(data?.length || 0));
      const state = getState().organisation;
      const isEmpty =
        state.totalOrganisationCount === 0 &&
        !state.searchText &&
        !state.filterType &&
        !state.status;

      dispatch(setOrganisationIsEmpty(isEmpty));
      dispatch(setOrgLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setOrgLoader(false));
    }
  };
