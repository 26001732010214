import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import LogoutContainer from "../../Layout/LogoutContainer";
import { HeaderStyle } from "../../Common/styles/header";
import UsersList from "./UsersList";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("query") ?? "");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();

    if (searchText) {
      params.set("query", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText]);

  return (
    <>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <Box sx={{ ml: "auto" }}>
          <LogoutContainer />
        </Box>
      </Box>
      <Box sx={HeaderStyle}>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          Users
        </Typography>

        <Box sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal(true)}
          >
            New User
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3, height: "calc(100vh - 144px)", overflow: "auto" }}>
        <UsersList
          searchText={searchText}
          showModal={showModal}
          setShowModal={setShowModal}
          setSearchText={setSearchText}
        />
      </Box>
    </>
  );
};

export default Users;
