import { useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  SxProps,
  Typography,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import GeneralAndSiteInfo from "./Steps/GeneralAndSiteInfo";
import Features from "./Steps/Features";

type Props = {
  closeDrawer: () => void;
  refreshPage: () => void;
};

const CustomStepWrapper: SxProps = {
  mb: 3,
  "& .MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStep-root": {
    width: "50%",
  },
};

const CustomStepStyle: SxProps = {
  "& .MuiSvgIcon-root.Mui-completed": {
    color: "#70AE71",
  },
  "& .MuiSvgIcon-root.Mui-active": {
    color: "primary.main",
  },
  "& .MuiStepIcon-root": {
    height: "34px",
    width: "34px",
  },
  "& .MuiStepLabel-label": {
    fontSize: "18px",
    fontWeight: "500",
  },
};

const studySteps = ["General and Site Information", "Features"];

const AddStudyDetailDrawer = ({ closeDrawer, refreshPage }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [studyId, setStudyId] = useState<string>("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box
      sx={{
        width: "900px",
        p: 2.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "25px",
        }}
      >
        <Typography variant="subtitle2" fontWeight="bold" color="primary.main">
          Add a New Study
        </Typography>
        <IconButton onClick={closeDrawer}>
          <CloseRounded sx={{ color: "text.secondary" }} />
        </IconButton>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stepper activeStep={activeStep} sx={CustomStepWrapper}>
          {studySteps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps} sx={CustomStepStyle}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ p: 3, overflow: "auto", height: "calc(100vh - 165px)" }}>
          {activeStep === 0 && (
            <GeneralAndSiteInfo
              handleNext={handleNext}
              setStudyId={setStudyId}
            />
          )}
          {activeStep === 1 && (
            <Features
              handleBack={handleBack}
              studyId={studyId}
              refreshPage={refreshPage}
              closeDrawer={closeDrawer}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddStudyDetailDrawer;
