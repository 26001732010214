import { SxProps } from "@mui/material";

export const SearchBox: SxProps = {
  p: 2,
  backgroundColor: "#FFFFFF",
  display: "flex",
  gap: "16px",
  borderRadius: "8px 8px 0 0 ",
};

export const TableBodyContent: SxProps = {
  fontWeight: "400",
  fontSize: "14px",
};

export const CreateFormInputRow: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  width: "70%",
};

export const CreateFormBox: SxProps = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "12px",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: "28px",
};

export const UploadWrapper: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: 2,
  borderStyle: "dashed",
  height: "200px",
  borderRadius: "8px",
  background: "#F9FAFB",
  width: "60%",
  borderColor: "primary.main",
  "&:hover": {
    // bgcolor: "secondary.dark",
    bgcolor: "#f2f2f2",
    cursor: "pointer",
  },
};

export const ContentText: SxProps = {
  fontSize: "16px",
  fontWeight: "400",
  color: "text.primary",
};

export const filterBox: SxProps = {
  cursor: "pointer",
  color: "#637E85",
  height: "52px",
  borderRadius: "8px",
  border: "1px solid #e0e3eb",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 10px",
};
