import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { QuestionSlice } from "../../../Redux/reducers/responseSlice";
import { useParams } from "react-router-dom";
import ValidationField from "../ValidationField";
import { useState } from "react";
import {
  handleTextBlur,
  handleTextChange,
} from "../../../Redux/actions/responseEventAction";

type Props = {
  currentQuestion: QuestionSlice;
};

const Text = ({ currentQuestion }: Props) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const isPreview = useAppSelector((state) => !state.response.formEditable);

  const { responses, properties } = currentQuestion;
  const currentResponse = responses?.[0]?.textValue;

  const [error, setError] = useState("");

  const handleChange = (e: any) => {
    const error = dispatch(
      handleTextChange({
        id: currentQuestion.id,
        value: e.target.value,
        format: properties?.format || "",
      })
    );
    if (error !== null) setError(error);
  };

  const handleBlur = (e: any) => {
    const error = dispatch(
      handleTextBlur({
        id: currentQuestion?.id,
        value: e.target.value,
        format: properties?.format || "",
        studyId,
        surveySlug,
      })
    );
    if (error !== null) setError(error);
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md: "80%",
        },
        pl: "60px",
      }}
      gap={1}
    >
      <TextField
        name="textValue"
        fullWidth
        placeholder="Enter Value"
        value={currentResponse || ""}
        multiline={properties?.allowMultiline}
        rows={properties?.allowMultiline ? 2 : 1}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
        InputProps={{ readOnly: isPreview }}
        error={!!error}
        helperText={error}
      />

      {responses?.[0]?.questionValidation && (
        <ValidationField
          validationObject={responses?.[0]?.questionValidation}
        />
      )}
    </Stack>
  );
};
export default Text;
