import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { ModalActionButtonStyles } from "../../Common/styles/modal";

type Props = {
  data?: any;
  handleNext: () => void;
  setStudyId: (value: string) => void;
};
// const studyTypeOptions = [
//   { label: "Production", value: "production" },
//   { label: "Test", value: "test" },
//   { label: "Example", value: "example" },
// ];

let schema = yup.object().shape({
  name: yup
    .string()
    .required("Study Name is Required")
    .min(4, "Study Name must be at least 4 Characters Long"),
  // type: yup.string().required("Study Type is Required"),
  // principalInvastigator: yup.string().optional(),

  siteName: yup.string().required("Site Name is Required"),
  siteAbbreviation: yup.string().required("Site Abbreviation is Required"),
  countryId: yup.string().required("Country is Required"),
  // siteCode: yup.string().required("Site Code is Required"),
  // address: yup.string().optional(),
});

const GeneralAndSiteInfo = ({ data, handleNext, setStudyId }: Props) => {
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [countriesList, setCountriesList] = useState<any[]>([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/data/countries`);
        const newData = res?.data?.data?.map((country: any) => ({
          value: country?.valueId,
          label: country?.label,
        }));
        setCountriesList(newData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchCountries();
  }, []);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        name: values?.name,
        // type: values?.type,
        // principalInvastigator: values?.principalInvastigator,
        site: {
          name: values?.siteName,
          abbreviation: values?.siteAbbreviation,
          countryId: values?.countryId,
          // code: values?.siteCode,
          // address: values?.address,
        },
      };
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.patch(`/study/${data?.id}`, body);
      } else {
        res = await http.post(`/study`, body);
      }
      setStudyId(res?.data?.data?.id);
      toastMessage("success", res?.data?.message);
      setSubmitLoader(false);
      handleNext();
    } catch (err) {
      errorToastMessage(err as Error);
      setSubmitLoader(false);
    }
  };
  return (
    <Box>
      {/* <Typography fontSize="16px" fontWeight={400} color="text.secondary">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem voluptas
        aut necessitatibus rerum sed nobis error debitis! Facilis porro odio eum
        maiores numquam quae dolorum.
      </Typography>
      <Divider sx={{ my: 2.5 }} /> */}
      {!loading ? (
        <Formik
          initialValues={{
            name: data?.studyName || "",
            // type: data?.type || "test",
            // principalInvastigator: data?.principalInvestigator || "",
            siteName: data?.siteName || "",
            siteAbbreviation: data?.abbreviation || "",
            countryId: data?.countryId || "",
            // siteCode: data?.siteCode || "",
            // address: data?.address || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ getFieldProps, errors, touched, setFieldValue, values }) => (
            <Form>
              <Typography
                fontWeight={500}
                fontSize={20}
                color="text.primary"
                mb={2.5}
              >
                General Information
              </Typography>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="study-name">
                  Study Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  placeholder="Study name"
                  id="study-name"
                  {...getFieldProps("name")}
                  error={touched?.name && errors?.name ? true : false}
                  helperText={
                    touched?.name && errors?.name
                      ? (errors?.name as string)
                      : " "
                  }
                />
              </FormControl>
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="study-type">
                  Study Type <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  value={values.type}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                  }}
                  fullWidth
                  id="study-type"
                >
                  {studyTypeOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.type && errors?.type ? true : false}
                >
                  {touched?.type && errors?.type
                    ? (errors?.type as string)
                    : " "}
                </FormHelperText>
              </FormControl> */}
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="principal-invastigator">
                  Principal Invastigator
                </FormLabel>
                <TextField
                  placeholder="Principal Invastigator"
                  id="principal-invastigator"
                  {...getFieldProps("principalInvastigator")}
                  error={
                    touched?.principalInvastigator &&
                    errors?.principalInvastigator
                      ? true
                      : false
                  }
                  helperText={
                    touched?.principalInvastigator &&
                    errors?.principalInvastigator
                      ? (errors?.principalInvastigator as string)
                      : " "
                  }
                />
              </FormControl> */}
              <Divider sx={{ my: 2.5 }} />

              <Typography
                fontWeight={500}
                fontSize={20}
                color="text.primary"
                mb={2.5}
              >
                Site Information
              </Typography>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="site-name">
                  Site Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  placeholder="Site name"
                  id="site-name"
                  {...getFieldProps("siteName")}
                  error={touched?.siteName && errors?.siteName ? true : false}
                  helperText={
                    touched?.siteName && errors?.siteName
                      ? (errors?.siteName as string)
                      : " "
                  }
                />
              </FormControl>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="country-id">
                  Country <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  value={values.countryId}
                  onChange={(e) => {
                    setFieldValue("countryId", e.target.value);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: { maxHeight: "300px", overflowY: "auto" },
                    },
                  }}
                  fullWidth
                  id="country-id"
                >
                  {countriesList.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.countryId && errors?.countryId ? true : false}
                >
                  {touched?.countryId && errors?.countryId
                    ? (errors?.countryId as string)
                    : " "}
                </FormHelperText>
              </FormControl>
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="site-code">
                  Site Code
                </FormLabel>
                <TextField
                  placeholder="Site code"
                  id="site-code"
                  {...getFieldProps("siteCode")}
                  error={touched?.siteCode && errors?.siteCode ? true : false}
                  helperText={
                    touched?.siteCode && errors?.siteCode
                      ? (errors?.siteCode as string)
                      : " "
                  }
                  inputProps={{ maxLength: 6 }}
                />
              </FormControl> */}
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="site-abbreviation">
                  Site Abbreviation <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  placeholder="Site abbreviation"
                  id="site-abbreviation"
                  {...getFieldProps("siteAbbreviation")}
                  error={
                    touched?.siteAbbreviation && errors?.siteAbbreviation
                      ? true
                      : false
                  }
                  helperText={
                    touched?.siteAbbreviation && errors?.siteAbbreviation
                      ? (errors?.siteAbbreviation as string)
                      : " "
                  }
                  inputProps={{ maxLength: 6 }}
                />
              </FormControl>
              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <Button type="submit" variant="contained">
                    Next Step
                  </Button>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="address">
                  Address
                </FormLabel>
                <TextField
                  placeholder="Address"
                  id="address"
                  {...getFieldProps("address")}
                  error={touched?.address && errors?.address ? true : false}
                  helperText={
                    touched?.address && errors?.address
                      ? (errors?.address as string)
                      : " "
                  }
                />
              </FormControl> */}
            </Form>
          )}
        </Formik>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
          <CircularProgress size={25} />
        </Box>
      )}
    </Box>
  );
};

export default GeneralAndSiteInfo;
