import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import SignIn from "./Components/Auth/SignIn";
import AppLayout from "./Components/Layout/AppLayout";
import { UnAuthenticatedRoute, AuthenticatedRoute } from "./utils/Route";

import Studies from "./Components/Studies/Studies";
// import Survey from "./Components/Survey/Survey";
import ChangePassword from "./Components/Auth/ChangePassword";
import Error404 from "./Components/Layout/404";
import CustomSignIn from "./Components/Auth/CustomSignIn";
// import WelcomePage from "./Components/Survey/WelcomePage";
// import ThankYouPage from "./Components/Survey/ThankYouPage";
import SurveyWrapper from "./Components/Survey/SurveyWrapper";
import ThankYouWrapper from "./Components/Survey/ThankYouWrapper";
import QuestionPrintPhase from "./Components/QuestionBuilder/QuestionPrint/QuestionPrintPhase";
import QuestionPrintForm from "./Components/QuestionBuilder/QuestionPrint/QuestionPrintForm";
import Organisations from "./Components/Organisations/Organisations";
import CreateOrganisation from "./Components/Organisations/CreateOrganisation";
import ViewOrganisation from "./Components/Organisations/ViewOrganisation/ViewOrganisation";
import Features from "./Components/Features/Features";
import OrganisationSettings from "./Components/OrganisationAdmin/Settings/Settings";
import OrganisationUsers from "./Components/OrganisationAdmin/Users/Users";
import OrganisationUserDetails from "./Components/OrganisationAdmin/Users/OrganisationUserDetails";
import OTPVerification from "./Components/Auth/OTPVerification";
import UserRegistration from "./Components/Auth/UserRegistration";

const routes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <UnAuthenticatedRoute>
        <Outlet />
      </UnAuthenticatedRoute>
    ),
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "login/:slug",
        element: <CustomSignIn />,
      },
      {
        path: "register/:slug",
        element: <UserRegistration />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "signup",
        element: <SetPassword />,
      },
      {
        path: "2fa",
        element: <OTPVerification />,
      },
    ],
  },
  {
    path: "/profile/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/app",
    element: (
      <AuthenticatedRoute>
        <AppLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "studies",
        element: <Studies />,
      },
      {
        path: "organisation",
        children: [
          {
            index: true,
            element: <Organisations />,
          },
          {
            path: "builder/:id",
            element: <CreateOrganisation />,
          },
          {
            path: "view/:id",
            element: <ViewOrganisation />,
          },
        ],
      },
      {
        path: "features",
        element: <Features />,
      },
      {
        path: "settings/:id",
        element: <OrganisationSettings />,
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <OrganisationUsers />,
          },
          {
            path: "details/:id",
            element: <OrganisationUserDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "surveys/:surveySlug",
    element: <SurveyWrapper />,
  },
  {
    path: "surveys/:surveySlug/thank-you",
    element: <ThankYouWrapper />,
  },
  {
    path: "404",
    element: <Error404 />,
  },
  {
    path: "print/study/:id/phase/:type",
    element: (
      <AuthenticatedRoute>
        <QuestionPrintPhase />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "print/study/:id/form/:formId",
    element: (
      <AuthenticatedRoute>
        <QuestionPrintForm />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/studies/:id",
    lazy: async () => {
      const { StudyLayoutComp } = await import("./Routes/StudyRoutes");
      return { Component: StudyLayoutComp };
    },
    children: [
      {
        path: "dashboard",
        lazy: async () => {
          const { DashboardComp } = await import("./Routes/StudyRoutes");
          return { Component: DashboardComp };
        },
      },
      {
        path: "study-designer",
        lazy: async () => {
          const { StudyDesignerComp } = await import("./Routes/StudyRoutes");
          return { Component: StudyDesignerComp };
        },
      },
      {
        path: "records",
        lazy: async () => {
          const { RecordsComp } = await import("./Routes/StudyRoutes");
          return { Component: RecordsComp };
        },
      },
      {
        path: "responses/:participantId",
        children: [
          {
            index: true,
            lazy: async () => {
              const { ResponsesComp } = await import("./Routes/StudyRoutes");
              return { Component: ResponsesComp };
            },
          },
          {
            path: "details",
            lazy: async () => {
              const { ParticipantDetailsComp } = await import(
                "./Routes/StudyRoutes"
              );
              return { Component: ParticipantDetailsComp };
            },
          },
        ],
      },

      {
        path: "form/:formId/edit",
        lazy: async () => {
          const { QuestionBuilderComp } = await import("./Routes/StudyRoutes");
          return { Component: QuestionBuilderComp };
        },
      },
      {
        path: "form/:formId/preview",
        lazy: async () => {
          const { QuestionPreviewComp } = await import("./Routes/StudyRoutes");
          return { Component: QuestionPreviewComp };
        },
      },
      {
        path: "audit-trail",
        lazy: async () => {
          const { AuditTrailComp } = await import("./Routes/StudyRoutes");
          return { Component: AuditTrailComp };
        },
      },
      {
        path: "export",
        lazy: async () => {
          const { ExportComp } = await import("./Routes/StudyRoutes");
          return { Component: ExportComp };
        },
      },
      {
        path: "study-settings",
        lazy: async () => {
          const { StudySettingsComp } = await import("./Routes/StudyRoutes");
          return { Component: StudySettingsComp };
        },
      },
      {
        path: "study-theme",
        lazy: async () => {
          const { StudyThemePanelComp } = await import("./Routes/StudyRoutes");
          return { Component: StudyThemePanelComp };
        },
      },
      {
        path: "user-management/users",
        children: [
          {
            index: true,
            lazy: async () => {
              const { UsersComp } = await import("./Routes/StudyRoutes");
              return { Component: UsersComp };
            },
          },
          {
            path: ":userId",
            lazy: async () => {
              const { UserDetailComp } = await import("./Routes/StudyRoutes");
              return { Component: UserDetailComp };
            },
          },
        ],
      },

      {
        path: "user-management/forms",
        lazy: async () => {
          const { FormPermissionsComp } = await import("./Routes/StudyRoutes");
          return { Component: FormPermissionsComp };
        },
      },
      {
        path: "user-management/roles-and-permissions/:type",
        lazy: async () => {
          const { RolesListComp } = await import("./Routes/StudyRoutes");
          return { Component: RolesListComp };
        },
      },
      {
        path: "queries",
        lazy: async () => {
          const { QueriesComp } = await import("./Routes/StudyRoutes");
          return { Component: QueriesComp };
        },
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/app/studies" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/app/studies" replace />,
  },
]);

export default routes;
