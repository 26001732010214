export const featuresMap: any = {
  randomization: "randomization",
  randomizationUploadFile: "randomization.upload.file",
  algoRandomization: "algo.randomization",
  repeatedData: "repeated.data",
  survey: "survey",
  visit: "visit",
  queries: "queries",
};

export const formatFeaturesId = (featuresData: any[]) => {
  const selectedIds: string[] = [];

  featuresData.forEach((feature) => {
    if (feature.selected === "yes") {
      selectedIds.push(feature.id);
    }

    feature.subItems?.forEach((subItem: any) => {
      if (subItem.selected) {
        selectedIds.push(subItem.id);
      }
    });
  });

  return selectedIds;
};

export const computeSelectedState = (subItems: any) => {
  if (!subItems || subItems.length === 0) return "no";

  const allEnabled = subItems.every((subItem: any) => subItem.isEnabled);
  const someEnabled = subItems.some((subItem: any) => subItem.isEnabled);

  if (allEnabled) return "yes";
  if (someEnabled) return "partial";
  return "no";
};
