import React, { useEffect, useMemo, useState } from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
  // CircularProgress,
  // Menu,
  // MenuItem,
  // ListItemIcon,
  // ListItemText,
} from "@mui/material";
import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import { NoDataContainer, StyledTableCell } from "../../Common/styles/table";
// import { Edit, MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { LiveStatusTag } from "../../Common/UI/StatusTag";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { MoreVert } from "@mui/icons-material";
import AddNewUserModal from "./AddNewUserModal";
import { SearchBox } from "../../Organisations/Organisation";
import { debounce } from "lodash";
import { GridSearchIcon } from "@mui/x-data-grid";

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const UsersList = ({
  searchText,
  showModal,
  setShowModal,
  setSearchText,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [studyData, setStudyData] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  // const [sortOrder, setSortOrder] = useState("");
  // const [sortColumn, setSortColumn] = useState("");
  // const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudyData = async () => {
      try {
        setLoading(true);
        let url = `/study?`;
        if (searchText) {
          url += `&query=${searchText}`;
        }
        // if (sortOrder && sortColumn) {
        //   url += `&sortDirection=${sortOrder}&sortKey=${sortColumn}`;
        // }

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.map((item: any) => ({
          id: item?.id,
          firstName: item?.name || "-",
          lastName: item?.name || "-",
          phoneNumber: item?.name || "-",
          email: item?.name || "-",
          status: item?.status || "-",
          date: item?.createdAt
            ? DateTime.fromISO(item?.createdAt).toFormat("dd/MM/yy")
            : "-",
        }));

        setStudyData(newData || []);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchStudyData();
  }, [searchText, toggleLoader]);

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleCellClick = (id: string) => {
    navigate(`/app/users/details/${id}`);
  };
  // const openModal = () => {
  //   setShowModal(true);
  //   setAnchorEl(null);
  // };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );
  return (
    <Box>
      <Box sx={SearchBox}>
        <TextField
          sx={{ width: "70%" }}
          variant="outlined"
          placeholder="Search"
          defaultValue={searchText}
          onChange={(e) => modifySearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GridSearchIcon sx={{ color: "#637E85" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            <StyledTableCell>Phone Number</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Emp ID</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {!loading && studyData?.length > 0 && (
          <TableBody>
            {studyData?.map((row: any) => (
              <TableRow key={row?.id}>
                <StyledTableCell
                  onClick={() => handleCellClick(row?.id)}
                  sx={{ cursor: "pointer" }}
                >
                  {row?.firstName}
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => handleCellClick(row?.id)}
                  sx={{ cursor: "pointer" }}
                >
                  {row?.lastName}
                </StyledTableCell>
                <StyledTableCell>{row?.phoneNumber}</StyledTableCell>
                <StyledTableCell>{row?.email}</StyledTableCell>
                <StyledTableCell>
                  <LiveStatusTag
                    status={row?.status === "live" ? "Live" : "Not Live"}
                  />
                </StyledTableCell>
                <StyledTableCell>{row?.date}</StyledTableCell>
                <StyledTableCell align="right">
                  {row?.status === "not_live" && (
                    <IconButton onClick={(e) => handleMenuClick(e, row)}>
                      <MoreVert />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!loading && studyData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        <MenuItem>
          <ListItemText>User Details</ListItemText>
          {/* {menuLoader && (
            <ListItemIcon>
              <CircularProgress size={18} sx={{ ml: 1 }} />
            </ListItemIcon>
          )} */}
        </MenuItem>
      </Menu>

      {showModal && (
        <AddNewUserModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default UsersList;
