import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  Modal,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { AxiosResponse } from "axios";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { useMemo, useState } from "react";
import http from "../../utils/http";
import { debounce } from "lodash";
import { formatTags } from "./AddFeatureModal";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  parentDataId?: any;
  data?: any;
  refreshPage: () => void;
};

const categoryOptions = [
  { label: "Functional", value: "functional" },
  { label: "Page", value: "page" },
  { label: "Device Type", value: "device_type" },
  { label: "Menu", value: "menu" },
  { label: "API", value: "api" },
  { label: "Entity", value: "entity" },
];

const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Active New", value: "active_new" },
  { label: "Active Beta", value: "active_beta" },
  { label: "Active Regular", value: "active_regular" },
  { label: "Hold", value: "hold" },
  { label: "Deprecated", value: "deprecated" },
];

let schema = yup.object().shape({
  featureName: yup
    .string()
    .required("Feature Name is Required")
    .max(100, "Feature Name should not exceed 100 characters")
    .test(
      "is-alphabetic",
      "Feature Name should only contain alphabets and spaces",
      (value) => /^[A-Za-z\s]+$/.test(value || "")
    ),
  healperText: yup
    .string()
    .optional()
    .max(100, "Helper Text should not exceed 100 characters"),
  category: yup.string().required("Category is Required"),
  tags: yup.array().of(yup.mixed().optional()).optional(),
  status: yup.string().required("Status is Required"),
});

const AddSubFeatureModal = ({
  parentDataId,
  data,
  showModal,
  closeModal,
  refreshPage,
}: Props) => {
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [tags, setTags] = useState<any[]>([]);

  // const dispatch = useAppDispatch();

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);
      const { addTagIds, addTags, removeTagIds } = formatTags(
        values.tags,
        data?.tags || []
      );
      const body: any = {
        name: values?.featureName,
        category: values?.category,
        helperText: values?.helperText,
        addTagIds: addTagIds,
        addTags: addTags,
        removeTagIds: removeTagIds,
      };
      if (!data?.id || values.status !== data?.status) {
        body.status = values.status;
      }
      if (parentDataId) {
        body.parentFeatureId = parentDataId;
      }
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.patch(`/features/${data?.id}`, body);
      } else {
        res = await http.post(`/features`, body);
      }
      toastMessage("success", res.data.message);
      closeModal();
      setButtonLoader(false);
      refreshPage();
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };
  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            let url = `/tags/search?query=${value}`;
            const res: AxiosResponse = await http.get(url);
            const resData = res?.data?.data?.rows;
            const tagOptions: any[] = resData.map((tag: any) => ({
              id: tag.id,
              name: tag.name,
            }));
            setTags(tagOptions);
            setSearchLoader(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={data?.id ? "Edit Sub Feature" : "Create Sub Feature"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            featureName: data?.featureName || "",
            category: data?.category || "functional",
            helperText: data?.helperText || "",
            tags: data?.tags || [],
            status: data?.status || "draft",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, setFieldValue, values }) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="feature-name">
                  Feature Name <span style={{ color: "#f16262" }}>*</span>
                </FormLabel>
                <TextField
                  id="feature-name"
                  placeholder="Feature Name"
                  {...getFieldProps("featureName")}
                  inputProps={{
                    maxLength: 100,
                  }}
                  error={
                    touched?.featureName && errors?.featureName ? true : false
                  }
                  helperText={
                    touched?.featureName && errors?.featureName
                      ? (errors?.featureName as string)
                      : " "
                  }
                />
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="helper-text">
                  Helper Text
                </FormLabel>
                <TextField
                  id="helper-text"
                  placeholder="Helper Text"
                  {...getFieldProps("helperText")}
                  inputProps={{
                    maxLength: 100,
                  }}
                  error={
                    touched?.helperText && errors?.helperText ? true : false
                  }
                  helperText={
                    touched?.helperText && errors?.helperText
                      ? (errors?.helperText as string)
                      : " "
                  }
                />
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>
                  Category <span style={{ color: "#f16262" }}>*</span>
                </FormLabel>
                <Select
                  value={values.category}
                  onChange={(e) => {
                    setFieldValue("category", e.target.value);
                  }}
                  fullWidth
                  id="category"
                >
                  {categoryOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.category && errors?.category ? true : false}
                >
                  {touched?.category && errors?.category
                    ? (errors?.category as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <FormControl sx={{ ...InputWrapper, mb: 2 }}>
                <FormLabel sx={LabelStyle}>Tags</FormLabel>
                <Autocomplete
                  freeSolo
                  multiple
                  filterOptions={(x) => x}
                  onInputChange={(_1: any, value: any, reason: string) => {
                    if (reason === "input") handleSearch(value);
                  }}
                  onChange={(_1: any, newValue: any) => {
                    setFieldValue("tags", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    const optionTitle =
                      typeof option === "string" ? option : option.id;
                    const valueTitle =
                      typeof value === "string" ? value : value.id;
                    return optionTitle === valueTitle;
                  }}
                  value={values?.tags}
                  options={tags}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  loading={searchLoader}
                  loadingText={<CircularProgress size={20} />}
                  noOptionsText="No Results"
                  clearOnBlur={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search tags by typing..."
                    />
                  )}
                />
              </FormControl>

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>
                  Status <span style={{ color: "#f16262" }}>*</span>
                </FormLabel>
                <Select
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  fullWidth
                  id="status"
                >
                  {statusOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.status && errors?.status ? true : false}
                >
                  {touched?.status && errors?.status
                    ? (errors?.status as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <Box sx={ModalActionButtonStyles}>
                {!buttonLoader ? (
                  <>
                    <Button variant="outlined" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddSubFeatureModal;
