import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import {
  StyledTabs,
  StyledTab,
  a11yProps,
  // MainTabPanel,
  CustomTabPanel,
} from "../../Common/UI/TabPanel";
import { useAppSelector } from "../../../Redux/hooks";
// import OverviewTab from "./Tabs/OverviewTab";
import LogoutContainer from "../../Layout/LogoutContainer";
// import Features from "./Tabs/Features";
// import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Features from "./Tabs/Features";
import UserTheme from "./Tabs/UserTheme";
import { permissions } from "../../../utils/roles";

const OrganisationSettings = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [type, setType] = useState(searchParams.get("type") ?? "features");
  const [toggleLoader, setToggleLoader] = useState(false);
  const [editFeatures, setEditFeatures] = useState(false);
  const { studyPermissions } = useAppSelector((state) => state.user);
  const [canEditSettings] = useState(
    studyPermissions.includes(permissions.studySettings)
  );

  useEffect(() => {
    const params = new URLSearchParams();
    if (type) {
      params.set("type", type);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, type]);

  const handleChange = (_1: any, val: string) => {
    setType(val);
  };

  // const handleBack = () => {
  //   navigate(-1);
  // };
  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };
  const handleEditFeatures = () => {
    refreshPage();
    setEditFeatures(true);
  };
  const handleCloseEditFeatures = () => {
    setEditFeatures(false);
    refreshPage();
  };

  return (
    <>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <Box sx={{ ml: "auto" }}>
          <LogoutContainer />
        </Box>
      </Box>
      {/* <Box sx={HeaderStyle}>
        <IconButton onClick={handleBack}>
          <ChevronLeft sx={{ cursor: "pointer" }} fontSize="large" />
        </IconButton>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          Organisation
        </Typography>
      </Box> */}
      <StyledHeader margin={0}>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <Typography fontSize={20} fontWeight={600} color="text.primary">
            {editFeatures
              ? "Edit Features"
              : type === "overview"
              ? "Settings"
              : "Settings"}
          </Typography>
        </Box>

        {type === "features" && editFeatures ? (
          !submitLoader ? (
            <Box sx={HeaderRightContent}>
              <Button variant="outlined" onClick={handleCloseEditFeatures}>
                Cancel
              </Button>
              <Button variant="contained">Save</Button>
            </Box>
          ) : (
            <CircularProgress size={25} />
          )
        ) : (
          type === "features" && (
            <Box sx={HeaderRightContent}>
              <Button
                variant="outlined"
                onClick={handleEditFeatures}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Box>
          )
        )}
      </StyledHeader>
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Features" value="features" {...a11yProps(0)} />
          <StyledTab label="User Theme" value="user_theme" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ p: 3, overflow: "auto", height: "calc(100vh - 201px)" }}>
        <CustomTabPanel value={type} index={"features"}>
          <Features
            handleCloseEditFeatures={handleCloseEditFeatures}
            toggleLoader={toggleLoader}
            editFeatures={editFeatures}
          />
        </CustomTabPanel>
        <CustomTabPanel value={type} index={"user_theme"}>
          <UserTheme canEdit={canEditSettings} />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default OrganisationSettings;
