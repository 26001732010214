import { Button, Box, Typography } from "@mui/material";
import OrganisationTable from "./OrganisationTable";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import LogoutContainer from "../Layout/LogoutContainer";
import { HeaderStyle } from "../Common/styles/header";

const Organisations = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <Box sx={{ ml: "auto" }}>
          <LogoutContainer />
        </Box>
      </Box>
      <Box sx={HeaderStyle}>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          Organisation
        </Typography>

        <Box sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => navigate("/app/organisation/builder/new")}
          >
            Create Organisation
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3, height: "calc(100vh - 144px)", overflow: "auto" }}>
        <OrganisationTable />
      </Box>
    </Box>
  );
};

export default Organisations;
