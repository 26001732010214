import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { colFlexStyle, commonContainerWrapper } from "../../Common/styles/flex";
import { useAppSelector } from "../../../Redux/hooks";
import { permissions } from "../../../utils/roles";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
//   import NewUserModal from "./Modals/NewUserModal";
import { useNavigate, useParams } from "react-router-dom";
// import http from "../../../utils/http";
import { errorToastMessage } from "../../../utils/toast";
import {
  NoDataContainer,
  StyledTableCell,
  TableBorderRadiusTopLeftRight,
} from "../../Common/styles/table";
import { Add, ArrowBack } from "@mui/icons-material";
import LogoutContainer from "../../Layout/LogoutContainer";

const dummyData = {
  id: 1,
  firstName: "John",
  lastName: "Doe",
  phoneNumber: "1234567890",
  email: "johndoe@example.com",
  empId: "100111",
  role: "Admin",
  tData: [
    {
      id: 1,
      study: "Study 1",
      action: "Remove",
    },
    {
      id: 2,
      study: "Study 2",
      action: "Remove",
    },
  ],
};

const OrganisationUserDetails = () => {
  const navigate = useNavigate();
  const { studyPermissions } = useAppSelector((state) => state.user);
  const { toggleLoader } = useAppSelector((state) => state.administrators);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [tableData, setTableData] = useState<any[]>([]);
  const { id: studyId, userId } = useParams();
  const [canEdit] = useState(
    studyPermissions.includes(permissions.userManagement)
  );

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        // let url = `/study/${studyId}/users/${userId}`;
        // const res = await http.get(url);
        // const userRes = res?.data?.data;
        // let newData: any = {};
        // newData = {
        //   firstName: userRes?.user?.firstName || "-",
        //   lastName: userRes?.user?.lastName || "-",
        //   email: userRes?.user?.email || "-",
        //   role: userRes?.user?.role || "-",
        //   modalData: {
        //     userId: userRes?.userId,
        //     email: userRes?.user?.email,
        //     roleSites: userRes?.user?.siteRoles,
        //     studyRole: {
        //       role: {
        //         id: userRes?.user?.studyRole?.role?.id,
        //       },
        //     },
        //   },
        // };
        // const tData = userRes?.user?.siteRoles?.map((item: any) => ({
        //   id: item?.id,
        //   role: item?.role?.label || "-",
        //   site: item?.site?.name || "-",
        // }));
        setData(dummyData);
        setTableData(dummyData.tData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchDetails();
  }, [studyId, userId, toggleLoader]);
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <Stack
          sx={{
            flex: 1,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <>
          <Box
            sx={{
              height: "64px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "0px 24px",
              bgcolor: "#FFFFFF",
              borderBottom: "1px solid #E5E7EB",
            }}
          >
            <Box sx={{ ml: "auto" }}>
              <LogoutContainer />
            </Box>
          </Box>
          <StyledHeader>
            <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
              <IconButton onClick={handleBack} sx={{ color: "text.primary" }}>
                <ArrowBack />
              </IconButton>
              <Typography fontSize={20} fontWeight={600} color="text.primary">
                User Details
              </Typography>
            </Box>

            {canEdit && (
              <Box sx={HeaderRightContent}>
                <Button variant="contained" onClick={openModal}>
                  Edit
                </Button>
              </Box>
            )}
          </StyledHeader>
          <Box sx={{ p: 3, overflow: "auto", height: "calc(100vh - 190px)" }}>
            <Box sx={commonContainerWrapper}>
              <Typography fontSize={20} fontWeight={600} color="text.primary">
                User Details
              </Typography>
              <Box sx={{ ...colFlexStyle, gap: 3, my: 3 }}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="text.primary"
                    >
                      First Name
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color="text.primary"
                    >
                      {data?.firstName}
                    </Typography>
                  </Box>
                  <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="text.primary"
                    >
                      Last Name
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color="text.primary"
                    >
                      {data?.lastName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="text.primary"
                    >
                      EMP ID
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color="text.primary"
                    >
                      {data?.empId}
                    </Typography>
                  </Box>
                  <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="text.primary"
                    >
                      Role
                    </Typography>
                    <Typography
                      fontSize={16}
                      fontWeight={400}
                      color="text.primary"
                    >
                      {data?.lastName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color="text.primary"
                  >
                    Email
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color="text.primary"
                  >
                    {data?.email}
                  </Typography>
                </Box>
                <Box sx={{ ...colFlexStyle, gap: "10px" }}>
                  <Typography
                    fontSize={16}
                    fontWeight={500}
                    color="text.primary"
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    fontSize={16}
                    fontWeight={400}
                    color="text.primary"
                  >
                    {data?.phoneNumber}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
              >
                <Typography fontSize={20} fontWeight={600} color="text.primary">
                  Study Access
                </Typography>
                <Button variant="outlined" startIcon={<Add />}>
                  Add Study
                </Button>
              </Box>

              <Box sx={{ width: "40%", mt: 2 }}>
                <Table sx={TableBorderRadiusTopLeftRight}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Studies</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {tableData?.length > 0 && (
                    <>
                      <TableBody>
                        {tableData?.map((row: any) => (
                          <TableRow key={row?.id}>
                            <StyledTableCell>{row?.study}</StyledTableCell>
                            <StyledTableCell>
                              <Typography
                                fontSize={16}
                                fontWeight={400}
                                color="#F05252"
                              >
                                {row?.action}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
                </Table>
                {tableData.length === 0 && (
                  <NoDataContainer>
                    <Typography variant="body1" color="gray">
                      No Data
                    </Typography>
                  </NoDataContainer>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {/* {showModal && (
        <AddNewStudyModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )} */}
    </>
  );
};

export default OrganisationUserDetails;
