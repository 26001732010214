import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Add, CloseRounded } from "@mui/icons-material";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import StudiesList from "./StudiesList";
import { GridSearchIcon } from "@mui/x-data-grid";
import LogoutContainer from "../Layout/LogoutContainer";
import { HeaderStyle } from "../Common/styles/header";
import { v4 as uuid } from "uuid";

const Studies = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("query") ?? "");
  const [searchId, setSearchId] = useState(uuid());
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();

    if (searchText) {
      params.set("query", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, searchText]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearchText(val);
      }, 500),
    [setSearchText]
  );
  const handleClearSearch = () => {
    setSearchText("");
    setSearchId(uuid());
  };

  return (
    <>
      <Box
        sx={{
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0px 24px",
          bgcolor: "#FFFFFF",
          borderBottom: "1px solid #E5E7EB",
        }}
      >
        <TextField
          key={searchId}
          placeholder="Search for Study"
          style={{ width: "30%" }}
          defaultValue={searchText}
          onChange={(e) => modifySearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GridSearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchText && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <CloseRounded />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ ml: "auto" }}>
          <LogoutContainer />
        </Box>
      </Box>
      <Box sx={HeaderStyle}>
        <Typography fontSize={20} fontWeight="medium" color="text.primary">
          My Studies
        </Typography>

        <Box sx={{ ml: "auto" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setOpenDrawer(true)}
          >
            New Study
          </Button>
        </Box>
      </Box>
      <Box sx={{ p: 3, height: "calc(100vh - 144px)", overflow: "auto" }}>
        <StudiesList
          searchText={searchText}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </Box>
    </>
  );
};

export default Studies;
