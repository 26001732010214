import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { AuthLayout, SignInWrapper } from "../Auth/auth.style";
import http from "../../utils/http";
import { useAppDispatch } from "../../Redux/hooks";
import { setUserAuth } from "../../Redux/reducers/userSlice";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { StyledButton } from "../Common/styles/button";
import OtpInput from "react-otp-input";
import { useInterval } from "../../utils/delay";
import { useNavigate } from "react-router-dom";

const ResendOtp = ({ clearInput }: { clearInput: Function }) => {
  const [timer, setTimer] = useState<number>(30);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number>(2);
  const [showRemainingAttempts, setShowRemainingAttempts] =
    useState<boolean>(false);

  useInterval(
    () => {
      if (timer > 1) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setCanResend(true);
      }
    },
    timer > 0 ? 1000 : null
  );

  const handleResendOTP = async () => {
    if (!canResend) return;
    // if (remaining === 0) navigate("/auth/login");

    try {
      setLoading(true);
      const otpToken = sessionStorage.getItem("otp-token") ?? "";
      const body: any = {
        token: otpToken,
        otpType: "mfa_login",
      };
      let url = "/user/auth/resend-otp";
      const res = await http.post(url, body);
      setRemaining(
        res.data?.data?.attempt?.maxRetryAttempts -
          res.data?.data?.attempt?.currentAttempt
      );
      toastMessage("success", res.data.message);
      setLoading(false);
      setTimer(30);
      setCanResend(false);
      setShowRemainingAttempts(true);
      clearInput();
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error);
    }
  };

  if (remaining === 0) {
    return null;
  }

  return (
    <>
      <Divider sx={{ mt: 6, mb: 3, borderColor: "#E0E3EB" }} />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
            textAlign: "center",
            mt: 1,
          }}
        >
          <Typography color="text.primary" fontWeight={500} fontSize="14px">
            Didn't receive OTP?
          </Typography>

          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Typography
              onClick={handleResendOTP}
              style={{
                color: canResend ? "#327091" : "#ccc",
                fontWeight: 500,
                fontSize: "14px",
                cursor: canResend ? "pointer" : "default",
              }}
            >
              {canResend ? "Resend OTP" : `Resend OTP in ${timer}s`}
            </Typography>
          )}
        </Box>
        {/* {showRemainingAttempts && remaining > 0 && (
          <Typography fontSize={14} fontWeight={500} color="error" mt={2}>
            OTP resends remaining: {remaining}
          </Typography>
        )} */}
      </Box>
    </>
  );
};

const OTPVerification = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [submitLoader, setSubmitLoader] = useState(false);
  const [otp, setOtp] = useState("");

  useLayoutEffect(() => {
    const otpToken = sessionStorage.getItem("otp-token") ?? "";
    if (!otpToken) {
      navigate("/auth/signin");
    }
    return () => {
      sessionStorage.removeItem("otp-token");
    };
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const otpToken = sessionStorage.getItem("otp-token") ?? "";
      const body: any = {
        otp: otp,
        token: otpToken,
        otpType: "mfa_login",
      };
      let url = "/user/auth/validate-otp";
      const res = await http.post(url, body);
      const token = res.data.data.accessToken;
      const refreshToken = res.data.data.refreshToken;
      const userId = res.data.data.id;
      const firstName = res?.data?.data?.firstName;
      const lastName = res?.data?.data?.lastName;
      localStorage.setItem("sm-access-token", token);
      localStorage.setItem("sm-refresh-token", refreshToken);
      localStorage.setItem("user-id", userId);
      localStorage.setItem("first-name", firstName);
      localStorage.setItem("last-name", lastName);
      dispatch(setUserAuth({ authenticated: true, role: "", userId }));
      sessionStorage.removeItem("otp-token");
      setSubmitLoader(false);
    } catch (err: any) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
      if (err?.response?.data?.metadata?.isRateLimited) {
        navigate("/auth/login");
      }
    }
  };

  const clearOtp = () => {
    setOtp("");
  };

  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Typography
          sx={{ fontSize: "30px", fontWeight: 600, color: "#111928" }}
        >
          Enter OTP
        </Typography>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 500, color: "#6B7280" }}
        >
          Enter the OTP sent to your email
        </Typography>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType={"tel"}
          shouldAutoFocus={true}
          renderInput={(props) => <input {...props} />}
          containerStyle={{
            marginTop: "40px",
            display: "flex",
            justifyContent: "space-between",
          }}
          inputStyle={{
            border: "1px solid #E0E3EB",
            width: "54px",
            height: "54px",
            borderRadius: "8px",
            textAlign: "center",
            color: "#111928",
            fontSize: "16px",
            fontWeight: 500,
          }}
        />
        {!submitLoader ? (
          <StyledButton
            onClick={handleSubmit}
            variant="contained"
            type="submit"
            fullWidth
            disabled={otp.length !== 6}
            sx={{ mt: 4 }}
          >
            Submit
          </StyledButton>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress size={25} />
          </Box>
        )}
        <ResendOtp clearInput={clearOtp} />
      </Box>
    </Box>
  );
};

export default OTPVerification;
