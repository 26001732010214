import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  Modal,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ClearIcon } from "@mui/x-date-pickers";
import { InputWrapper, LabelStyle } from "../Common/styles/form";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../Common/styles/modal";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setFeaturesFilter } from "../../Redux/reducers/featuresSlice";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  // studyId: any;
};
const statusOptions = [
  { label: "Draft", value: "draft" },
  { label: "Active New", value: "active_new" },
  { label: "Active Beta", value: "active_beta" },
  { label: "Active Regular", value: "active_regular" },
  { label: "Hold", value: "hold" },
  { label: "Deprecated", value: "deprecated" },
];
const categoryOptions = [
  { label: "Functional", value: "functional" },
  { label: "Page", value: "page" },
  { label: "Device Type", value: "device_type" },
  { label: "Menu", value: "menu" },
  { label: "API", value: "api" },
  { label: "Entity", value: "entity" },
];

let schema = yup.object().shape({
  category: yup.string().optional(),
  tag: yup.string().optional(),
  status: yup.string().optional(),
});

const FeatureFilterModal = ({ showModal, closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const { category, tag, status } = useAppSelector((state) => state.features);

  const submitHandler = async (values: any) => {
    dispatch(
      setFeaturesFilter({
        category: values?.category,
        tag: values?.tag,
        status: values?.status,
      })
    );
    closeModal();
  };
  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Filters"} onCloseClick={closeModal} />
        <Formik
          initialValues={{
            category: category || "",
            tag: tag || "",
            status: status || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ errors, touched, getFieldProps, setFieldValue, values }) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>Category</FormLabel>
                <Select
                  value={values.category}
                  onChange={(e) => {
                    setFieldValue("category", e.target.value);
                  }}
                  fullWidth
                  id="category"
                  endAdornment={
                    values.category !== "" && (
                      <InputAdornment
                        sx={{ marginRight: "10px" }}
                        position="end"
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue("category", "");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  {categoryOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.category && errors?.category ? true : false}
                >
                  {touched?.category && errors?.category
                    ? (errors?.category as string)
                    : " "}
                </FormHelperText>
              </FormControl>
              {/* <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="tag">
                  Tag
                </FormLabel>
                <TextField
                  id="tag"
                  placeholder="Tag"
                  {...getFieldProps("tag")}
                  error={touched?.tag && errors?.tag ? true : false}
                  helperText={
                    touched?.tag && errors?.tag ? (errors?.tag as string) : " "
                  }
                />
              </FormControl> */}

              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle}>Status</FormLabel>
                <Select
                  value={values.status}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                  }}
                  fullWidth
                  id="status"
                  endAdornment={
                    values.status !== "" && (
                      <InputAdornment
                        sx={{ marginRight: "10px" }}
                        position="end"
                      >
                        <IconButton
                          onClick={() => {
                            setFieldValue("status", "");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  {statusOptions.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={touched?.status && errors?.status ? true : false}
                >
                  {touched?.status && errors?.status
                    ? (errors?.status as string)
                    : " "}
                </FormHelperText>
              </FormControl>

              <Box sx={ModalActionButtonStyles}>
                <>
                  <Button
                    onClick={() => {
                      setFieldValue("category", "");
                      setFieldValue("tag", "");
                      setFieldValue("status", "");
                    }}
                    variant="outlined"
                  >
                    Clear All
                  </Button>
                  <Button onClick={closeModal} variant="outlined">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Apply
                  </Button>
                </>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default FeatureFilterModal;
