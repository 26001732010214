import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmptyOrganisationIcon } from "../Common/assets/Icons";

const EmptyOrganisation = () => {
  const navigate = useNavigate();
  const handleCreateOrg = () => {
    navigate("/app/organisation/builder/new");
  };
  return (
    <Box
      sx={{
        mt: "8%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <EmptyOrganisationIcon />
      <Typography fontWeight="medium" fontSize={22} sx={{ mt: 2 }}>
        Create Organisation
      </Typography>
      <Typography
        fontWeight="regular"
        fontSize={14}
        sx={{ my: 2.5, width: "75%", textAlign: "center" }}
      >
        Organisations are the clients who sponsor the studies in the platform
      </Typography>
      <Button variant="contained" onClick={handleCreateOrg}>
        Create Organisation
      </Button>
    </Box>
  );
};

export default EmptyOrganisation;
