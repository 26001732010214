import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { filterBox, SearchBox, TableBodyContent } from "./Organisation";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { fetchOrgData } from "../../Redux/actions/organisationAction";
import { NoDataContainer, StyledTableCell } from "../Common/styles/table";
import {
  setOrganisationSearchText,
  setSortColumn,
  setSortOrder,
} from "../../Redux/reducers/organisationSlice";
import { v4 as uuid } from "uuid";
// import EmptyOrganisation from "./EmptyOrganisation";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import {
  CloseRounded,
  ExpandMoreRounded,
  MoreVert,
  SortRounded,
  UnfoldMore,
} from "@mui/icons-material";
import OrganisationFilterModal from "./OrganisationFilterModal";
import EmptyOrganisation from "./EmptyOrganisation";

export const typeMap: any = {
  individual: "Individual Organisation",
  white_label: "White Label Organisation",
};

const statusMap: any = {
  draft: { label: "Draft", color: "#3F83F8" },
  active: { label: "Active", color: "#31C48D" },
  suspended: { label: "Suspended", color: "#F05252" },
  archived: { label: "Archived", color: "text.primary" },
};

const OrganisationTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [searchId, setSearchId] = useState(uuid());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    organisationData,
    totalOrganisationCount,
    organisationId,
    organisationName,
    primaryContact,
    filterType,
    status,
    searchText,
    sortOrder,
    sortColumn,
    loading,
    toggleLoader,
    isEmpty,
  } = useAppSelector((state) => state.organisation);

  useEffect(() => {
    dispatch(
      fetchOrgData(
        searchText,
        organisationId,
        organisationName,
        primaryContact,
        filterType,
        status,
        sortOrder,
        sortColumn
      )
    );
  }, [
    dispatch,
    searchText,
    status,
    toggleLoader,
    sortOrder,
    sortColumn,
    filterType,
    organisationId,
    organisationName,
    primaryContact,
    isEmpty,
  ]);

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      if (sortOrder === "ASC") {
        dispatch(setSortOrder("DESC"));
      } else {
        dispatch(setSortOrder(""));
        dispatch(setSortColumn(""));
      }
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortOrder("ASC"));
    }
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((value) => {
        dispatch(setOrganisationSearchText(value));
      }, 500),
    [dispatch]
  );

  const openFilterModal = () => {
    setShowFilterModal(true);
  };
  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const ViewOrganisationDetails = () => {
    const id = selectedRow?.id;
    navigate(`/app/organisation/view/${id}`);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleClearSearch = () => {
    dispatch(setOrganisationSearchText(""));
    setSearchId(uuid());
  };
  // const GotoStudies = () => {
  //   navigate("/app/organisation/builder/${row.id}");
  // };

  return (
    <Box>
      {!isEmpty && (
        <Box sx={SearchBox}>
          <TextField
            key={searchId}
            sx={{ width: "70%" }}
            variant="outlined"
            placeholder="Search for organisation"
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#637E85" }} />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <CloseRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ ...filterBox, width: "30%" }} onClick={openFilterModal}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <SortRounded />
              <Typography>Filter</Typography>
            </Box>

            <ExpandMoreRounded />
          </Box>
        </Box>
      )}
      {!isEmpty && (
        <Box sx={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Logo</StyledTableCell>
                {/* <StyledTableCell sx={{ minWidth: 120 }}>
                Org ID
                <IconButton onClick={() => handleSort("orgId")}>
                  <UnfoldMore color={"primary"} />
                </IconButton>
              </StyledTableCell> */}
                <StyledTableCell sx={{ minWidth: 210 }}>
                  Organisation Name
                  <IconButton onClick={() => handleSort("name")}>
                    <UnfoldMore color={"primary"} />
                  </IconButton>
                </StyledTableCell>
                {/* <StyledTableCell sx={{ minWidth: 220 }}>
                Parent Organisation
                <IconButton onClick={() => handleSort("ParentOrgName")}>
                  <UnfoldMore color={"primary"} />
                </IconButton>
              </StyledTableCell> */}
                <StyledTableCell sx={{ minWidth: 210 }}>
                  Primary Contact
                </StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell sx={{ minWidth: 120 }}>Status</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>

            {!loading && totalOrganisationCount > 0 && (
              <>
                <TableBody>
                  {organisationData.map((row: any) => (
                    <TableRow
                      key={row.id}
                      // onClick={() =>
                      //   navigate(`/app/organisation/builder/${row.id}`)
                      // }
                    >
                      <StyledTableCell>
                        <Avatar
                          src={row?.signedLogoUrl}
                          sx={{
                            width: 50,
                            height: 50,
                            fontSize: 14,
                            bgcolor: "lightgray",
                            color: "#000",
                          }}
                        >
                          <PhotoSizeSelectActualOutlinedIcon />
                        </Avatar>
                      </StyledTableCell>

                      {/* <StyledTableCell sx={TableBodyContent}>
                      {row.orgId}
                    </StyledTableCell> */}
                      <StyledTableCell sx={TableBodyContent}>
                        {row.orgName}
                      </StyledTableCell>
                      {/* <StyledTableCell sx={TableBodyContent}>
                      {row.parentOrg}
                    </StyledTableCell> */}
                      <StyledTableCell sx={TableBodyContent}>
                        {row.primaryContact}
                      </StyledTableCell>
                      <StyledTableCell sx={TableBodyContent}>
                        {typeMap[row.type]}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          ...TableBodyContent,
                          color: statusMap?.[row.status]?.color,
                        }}
                      >
                        {statusMap?.[row.status]?.label}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVert />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </Box>
      )}

      {!loading && isEmpty && <EmptyOrganisation />}
      {!loading && totalOrganisationCount === 0 && !isEmpty && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={ViewOrganisationDetails}>
          <ListItemText>View organisation details</ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={GotoStudies}>
          <ListItemText>Go to studies</ListItemText>
        </MenuItem> */}
      </Menu>

      {showFilterModal && (
        <OrganisationFilterModal
          showModal={showFilterModal}
          closeModal={closeFilterModal}
        />
      )}
    </Box>
  );
};

export default OrganisationTable;
