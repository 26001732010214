import { pageSize } from "./../../Components/Common/styles/table";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PaginationProps = {
  page: number;
  pageSize: number;
};

export interface ParticipantsState {
  loading: boolean;
  featuresData: any[];
  totalFeatures: number;
  searchText: string;
  paginationModel: PaginationProps;
  sortOrder: string;
  sortColumn: string;
  category: string;
  status: string;
  tag: string;
}

const initialState: ParticipantsState = {
  loading: true,
  paginationModel: {
    page: 0,
    pageSize: pageSize,
  },
  searchText: "",
  featuresData: [],
  totalFeatures: 0,
  sortOrder: "",
  sortColumn: "",
  category: "",
  status: "",
  tag: "",
};

export const featuresSlice = createSlice({
  name: "participants",
  initialState: initialState,
  reducers: {
    setFeaturesLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFeaturesDetails: (state, action: PayloadAction<any>) => {
      state.featuresData = action.payload;
    },
    setFeaturesCount: (state, action: PayloadAction<number>) => {
      state.totalFeatures = action.payload;
    },
    setFeaturesPage: (state, action: PayloadAction<PaginationProps>) => {
      state.paginationModel = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.paginationModel.page = 0;
      state.searchText = action.payload;
    },
    setFeaturesSort: (
      state,
      action: PayloadAction<{ column: string; order: string }>
    ) => {
      state.sortColumn = action.payload.column;
      state.sortOrder = action.payload.order;
    },
    setFeaturesFilter: (
      state,
      action: PayloadAction<{
        category: string;
        tag: string;
        status: string;
      }>
    ) => {
      state.paginationModel.page = 0;
      state.category = action.payload.category;
      state.tag = action.payload.tag;
      state.status = action.payload.status;
    },
    reset: () => initialState,
  },
});

export const {
  setFeaturesLoader,
  setFeaturesDetails,
  setFeaturesCount,
  setFeaturesPage,
  setFeaturesSort,
  setFeaturesFilter,
  setSearchText,
  reset,
} = featuresSlice.actions;

export default featuresSlice.reducer;
