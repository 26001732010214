import { InputAdornment, Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { QuestionSlice } from "../../../Redux/reducers/responseSlice";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ValidationField from "../ValidationField";
import {
  handleNumberChange,
  triggerSave,
} from "../../../Redux/actions/responseEventAction";

type Props = {
  currentQuestion: QuestionSlice;
};

const NumberField = ({ currentQuestion }: Props) => {
  const { id: studyId, surveySlug } = useParams();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>("");

  const isPreview = useAppSelector((state) => !state.response.formEditable);

  const { responses, properties } = currentQuestion;
  const currentResponse = responses?.[0]?.numberValue;

  const handleChange = (value: string) => {
    const error = dispatch(
      handleNumberChange({
        value: value,
        id: currentQuestion.id,
        properties,
      })
    );
    if (error !== null) setError(error);
  };

  const handleBlur = () => {
    if (currentQuestion?.id && !error) {
      dispatch(
        triggerSave({
          studyId,
          id: currentQuestion.id,
          surveySlug,
        })
      );
    }
  };

  return (
    <Stack
      sx={{
        width: {
          xs: "100%",
          md:
            typeof properties?.fieldWidth === "number"
              ? 32 + (properties?.fieldWidth - 4) * 3 + "%"
              : "80%",
        },
        pl: "60px",
      }}
    >
      <TextField
        type="number"
        name="numberValue"
        fullWidth
        placeholder="Enter Value"
        value={typeof currentResponse === "number" ? currentResponse : ""}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        InputProps={{
          readOnly: isPreview,
          inputProps: {
            min: properties?.min,
            max: properties?.max,
          },
          endAdornment: properties?.measurementUnit ? (
            <InputAdornment position="end">
              {properties.measurementUnit}
            </InputAdornment>
          ) : null,
        }}
        error={!!error}
        helperText={error}
      />

      {responses?.[0]?.questionValidation && (
        <ValidationField
          validationObject={responses?.[0]?.questionValidation}
        />
      )}
    </Stack>
  );
};
export default NumberField;
