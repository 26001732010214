import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { LabelStyle } from "../../../Common/styles/form";
import { ContentText } from "../../Organisation";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { errorToastMessage } from "../../../../utils/toast";
import { NoDataContainer } from "../../../Common/styles/table";
import { commonContainerWrapper } from "../../../Common/styles/flex";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import { typeMap } from "../../OrganisationTable";

const OverviewTab = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`/organizations/${id}`);
        const response = res?.data?.data;
        const newData = {
          id: response?.id || "-",
          orgName: response?.name || "-",
          type: typeMap[response?.type] || "-",
          parentOrg: response?.parentOrg || "-",
          primaryContact: response?.mainContactName || "-",
          phoneNumber: response?.mainContactPhoneNumber || "-",
          email: response?.mainContactEmail || "-",
          // logoUrl: response?.logoUrl || "-",
          signedLogoUrl: response?.signedLogoUrl || "-",
        };
        setData(newData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    if (id) {
      fetchDetails();
    } else {
      setData({});
      setLoading(false);
    }
  }, [id, setData, setLoading]);
  const handleEdit = () => {
    navigate(`/app/organisation/builder/${id}`);
  };
  return (
    <Box sx={commonContainerWrapper}>
      {!loading && data.id && (
        <Stack gap={3}>
          <Box>
            <Typography sx={LabelStyle}>Organisation Name</Typography>
            <Typography sx={ContentText}>{data.orgName}</Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Type</Typography>
            <Typography sx={ContentText}>{data.type}</Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Parent Organisation</Typography>
            <Typography sx={ContentText}>{data.parentOrg}</Typography>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Primary Contact</Typography>
            <Typography sx={ContentText}>{data.primaryContact}</Typography>
          </Box>
          <Box display={"flex"} gap={12}>
            <Box>
              <Typography sx={LabelStyle}>Email</Typography>
              <Typography sx={ContentText}>{data.email}</Typography>
            </Box>
            <Box>
              <Typography sx={LabelStyle}>Phone Number</Typography>
              <Typography sx={ContentText}>{data.phoneNumber}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography sx={LabelStyle}>Logo</Typography>

            <Avatar
              src={data?.signedLogoUrl}
              sx={{
                width: 50,
                height: 50,
                fontSize: 14,
                bgcolor: "lightgray",
                color: "#000",
              }}
            >
              <PhotoSizeSelectActualOutlinedIcon />
            </Avatar>
          </Box>
          <Box>
            <Button variant="contained" onClick={handleEdit}>
              Edit
            </Button>
          </Box>
        </Stack>
      )}

      {!loading && !data.id && (
        <NoDataContainer>
          <Typography variant="body2" color="gray">
            Organisation doesn't exist
          </Typography>
        </NoDataContainer>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default OverviewTab;
