export const RandomizeField = () => {
  return <div>Randomize Participant</div>;
};

export const ExcludeField = () => {
  return <div>Exclude Participant</div>;
};

export const FileUploadField = () => {
  return <div>Attach necessary documents</div>;
};
