import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrgState {
  organisationData: any[];
  totalOrganisationCount: number;
  searchText: string;
  organisationId: string;
  organisationName: string;
  primaryContact: string;
  filterType: string;
  status: string;
  sortOrder: string;
  sortColumn: string;
  loading: boolean;
  toggleLoader: boolean;
  isEmpty: boolean;
}

const initialState: OrgState = {
  organisationData: [],
  totalOrganisationCount: 0,
  searchText: "",
  organisationId: "",
  organisationName: "",
  primaryContact: "",
  filterType: "",
  status: "",
  sortOrder: "",
  sortColumn: "",
  loading: true,
  toggleLoader: false,
  isEmpty: false,
};

export const organisationSlice = createSlice({
  name: "organisation",
  initialState: initialState,
  reducers: {
    setOrganisationData: (state, action: PayloadAction<any>) => {
      state.organisationData = action.payload;
    },
    setOrganisationIsEmpty: (state, action: PayloadAction<boolean>) => {
      state.isEmpty = action.payload;
    },
    setOrganisationCount: (state, action: PayloadAction<number>) => {
      state.totalOrganisationCount = action.payload;
    },
    setOrganisationSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<string>) => {
      state.sortOrder = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setOrganisationToggle: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    setOrgLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOrganisationFilter: (
      state,
      action: PayloadAction<{
        // organisationId: string;
        // organisationName: string;
        // primaryContact: string;
        filterType: string;
        status: string;
      }>
    ) => {
      // state.organisationId = action.payload.organisationId;
      // state.organisationName = action.payload.organisationName;
      // state.primaryContact = action.payload.primaryContact;
      state.filterType = action.payload.filterType;
      state.status = action.payload.status;
    },
  },
});

export const {
  setOrganisationData,
  setOrganisationCount,
  setOrganisationSearchText,
  setSortOrder,
  setSortColumn,
  setOrgLoader,
  setOrganisationToggle,
  setOrganisationFilter,
  setOrganisationIsEmpty,
} = organisationSlice.actions;

export default organisationSlice.reducer;
